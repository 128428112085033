import React, { useEffect } from 'react'
import SearchBar from './SearchBar'

const ChiSiamo = () => {
    useEffect(() => {
        setTimeout(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, 50);
    }, [])

    return (
        <div className='flex  xl:flex-row flex-col justify-center items-center w-full mb-[30px] xl:items-start xl:mt-[30px]'>
            <div className='flex flex-col xl:mt-0 md:mt-[20px] mt-[20px] xl:w-[800px] md:w-[750px] w-[350px]'>
                <div className='font-bold ml-[20px] mr-[20px] text-xl text-claim border-t-[3px] border-t-site-lite-orange border-b-[3px] border-b-site-lite-orange m-[10px]'>
                    Sono Clara Carnazzola, agente immobiliare dal 1990
                </div>
                <div className='ml-[20px] mr-[20px] pt-[5px] pb-[5px]' >
                    <div className='flex flex-row text-[15px] text-claim items-center'>
                        <p className='text-justify'>Sono titolare della mia omonima agenzia a Caspoggio, in Valmalenco, un piccolo meraviglioso borgo di montagna avvolto nelle Alpi della provincia di Sondrio.</p>
                    </div>
                </div>
                <div className='ml-[20px] mr-[20px] pt-[5px] pb-[5px]' >
                    <div className='flex flex-row text-[15px] text-claim items-center'>
                        <p className='text-justify'>Mi occupo di compravendite, di affittanze e di amministrazione di Condomini. Sono un’ottimista nata, cerco di mettere tutti nelle migliori condizioni e non lascio nulla al caso!</p>
                    </div>
                </div>
                <div className='ml-[20px] mr-[20px] pt-[5px] pb-[5px]' >
                    <div className='flex flex-row text-[15px] text-claim items-center'>
                        <p className='text-justify'>Posso contare, per svolgere le mie attività, del supporto referenziato ed affidabile di maestranze, professionisti e tecnici del settore.</p>
                    </div>
                </div>
                <br />
                <div className='ml-[20px] mr-[20px] pt-[5px] pb-[5px]' >
                    <div className='flex flex-row text-[15px] text-claim items-center'>
                        <p className='text-justify'>Vi aspetto per conoscervi di persona. Vi avviso, mi piace molto chiacchierare!</p>
                    </div>
                </div>
            </div>
            <SearchBar />
        </div>
    )
}

export default ChiSiamo