import React, { useContext, useEffect, useRef, useState } from "react";
import CercaItem from "./CercaItem";
import { FirestoreContext } from "./FirestoreProvider";
import { useLocation } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

const Cerca = () => {
    const DEFAULT_LOCATION = "defaultLocation";
    const DEFAULT_CONTRACT = "defaultContract";
    const DEFAULT_TIPOLOGY = "defaultTipologia";
    const DEFAULT_PRICE = "defaultPrice";
    const DEFAULT_LEASE = "defaultLease";
    const location = useLocation();

    const { getRemoteLocations, getPriceRange, getLeaseRange, retrieveContracts, getBuildingsSearch } = useContext(FirestoreContext);
    const { flattenTypologies, retrieveFlattenTypologies } = useContext(FirestoreContext);

    const [currentLocation, setCurrentLocation] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState("");
    const [currentContract, setCurrentContract] = useState(null);
    const [selectedContract, setSelectedContract] = useState("");
    const [selectedTipology, setSelectedTipology] = useState("");
    const [currentPriceRange, setCurrentPriceRange] = useState(null);
    const [selectedPriceRange, setSelectedPriceRange] = useState("");
    const [currentLeaseRange, setCurrentLeaseRange] = useState(null);
    const [selectedLeaseRange, setSelectedLeaseRange] = useState("");

    const [haveResult, setHaveResult] = useState(false);
    const [currentResult, setCurrentResult] = useState([]);
    const [spinner, setSpinner] = useState(true);
    const [startAt, setStartAt] = useState(null);
    const [hasMore, setHasMore] = useState(false);

    const itemsRef = useRef(null);

    const getContract = () => {
        let contract = retrieveContracts();
        setCurrentContract(contract);
    }

    const internalGetPriceRange = () => {
        let priceRange = getPriceRange();
        setCurrentPriceRange(priceRange);
    }

    const internalGetLeaseRange = () => {
        let leaseRange = getLeaseRange();
        setCurrentLeaseRange(leaseRange);
    }

    const handleLocationChange = (e) => {
        let location = e.target.value;
        if (location === DEFAULT_LOCATION) {
            location = "";
        }
        setSelectedLocation(location);
    }

    const handleContractChange = (e) => {
        let contract = e.target.value;
        if (contract === DEFAULT_CONTRACT) {
            contract = "";
        }
        setSelectedContract(contract);
        setSelectedLeaseRange("");
        setSelectedPriceRange("");
    }

    const handleTipologyChange = (e) => {
        let tipolgy = e.target.value;
        if (tipolgy === DEFAULT_TIPOLOGY) {
            tipolgy = "";
        }
        setSelectedTipology(tipolgy);
    }

    const handlePriceRangeChange = (e) => {
        let price = e.target.value;
        if (price === DEFAULT_PRICE) {
            price = "";
        }
        setSelectedPriceRange(price);
    }

    const handleLeaseRangeChange = (e) => {
        let lease = e.target.value;
        if (lease === DEFAULT_LEASE) {
            lease = "";
        }
        setSelectedLeaseRange(lease);
    }

    useEffect(() => {
        retrieveFlattenTypologies();
        getRemoteLocations()
            .then((res) => {
                setCurrentLocation([...res]);
            })
        getContract();
        internalGetPriceRange();
        internalGetLeaseRange();
        if (location.state ?? false) {
            const { localita, contract, tipology, price, lease, runSearch, itemList, hasMore, index } = location.state

            setSelectedLocation(localita);
            setSelectedContract(contract);
            setSelectedTipology(tipology);
            setSelectedPriceRange(price);
            setSelectedLeaseRange(lease);
            setHasMore(hasMore);

            // if (scrollPosition !== null && scrollPosition !== undefined) {
            //     document.getElementsByTagName("body").scrollTop = scrollPosition;
            //     window.scrollTo(0, 100);
            // }

            if (index !== null && index !== undefined) {
                setTimeout(() => {
                    console.log(document.getElementById(index).offsetTop)
                    let offset = document.getElementById(index).offsetTop - 200;
                    window.scrollTo({ top: offset, left: 0, behavior: "smooth" });
                    // document.getElementById(index).scrollIntoView();
                }, 50)
            }

            if (runSearch ?? false) {
                if (runSearch) {
                    internalSearch(localita, contract, tipology, price, lease, null);
                } else {
                    checkPreviousResult(itemList);
                }
            } else {
                checkPreviousResult(itemList);
            }
        } else {
            search();
        }

    }, [])

    const checkPreviousResult = (itemList) => {
        setSpinner(false);
        setHaveResult(itemList.length > 0);
        setCurrentResult(itemList);
    }

    const search = () => {
        setSpinner(true);
        setCurrentResult([]);
        setStartAt(null);
        internalSearch(selectedLocation, selectedContract, selectedTipology, selectedPriceRange, selectedLeaseRange, null);
    }

    const fetchMore = () => {
        internalSearch(selectedLocation, selectedContract, selectedTipology, selectedPriceRange, selectedLeaseRange, startAt);
    }

    const internalSearch = async (localita, contract, tipology, price, lease, startAt) => {
        await getBuildingsSearch(localita, contract, tipology, price, lease, startAt)
            .then((result) => {
                if (result.length === 0) {
                    setHasMore(false);
                }
                else {
                    setHaveResult(true);
                    setHasMore(true);

                    let app = [];
                    if (startAt ?? false) {
                        app = [...currentResult];
                    }
                    result.forEach(res => {
                        if (app.filter(c => c.idAnnuncio === res.idAnnuncio).length === 0) {
                            app = [...app, res];
                        }
                    })

                    setCurrentResult(app);
                    setStartAt(app[app.length - 1]);
                }
                setSpinner(false);
            })
            .catch((err) => {
                setSpinner(false);
                console.log(err);
            })
    }

    return (
        <>
            {
                spinner &&
                <div className="overlay"><div className="spinner"></div></div>
            }
            <div className='flex  xl:flex-row flex-col justify-center items-center w-full mb-[30px] xl:items-start xl:mt-[30px]'>
                <div className='flex xl:flex-row md:flex-col flex-col justify-center xl:items-start md:items-center items-center xl:mt-0 md:mt-[20px] mt-[20px] xl:w-xl md:w-md w-sm'>
                    <div className='bg-site-light-grey2 xl:mr-[20px] md:mr-0 mr-0 pt-[5px] pb-[5px] xl:w-[350px] md:w-full w-full'>
                        <div className='flex flex-col w-full justify-center'>
                            <div className='font-bold text-2xl text-claim border-t-[3px] border-t-site-lite-orange border-b-[3px] border-b-site-lite-orange m-[10px]'>
                                Cerca la tua casa
                            </div>
                            <div className='flex flex-col m-[10px] mb-[5px]'>
                                {
                                    currentLocation !== null &&
                                    <select className='select' onChange={handleLocationChange} value={selectedLocation}>
                                        <option key={DEFAULT_LOCATION} value={DEFAULT_LOCATION}>Località</option>
                                        {currentLocation.map((location, key) => {
                                            return (
                                                <option key={key} value={location.value}>{location.value}</option>
                                            )
                                        })}
                                    </select>
                                }
                            </div>
                            <div className='flex flex-col m-[10px] mt-[5px] mb-[5px]'>
                                {
                                    currentContract !== null &&
                                    <select className='select' onChange={handleContractChange} value={selectedContract}>
                                        <option key={DEFAULT_CONTRACT} value={DEFAULT_CONTRACT}>Contratto</option>
                                        {currentContract.map((contract, key) => {
                                            return (
                                                <option key={key} value={contract.value}>{contract.value}</option>
                                            )
                                        })}
                                    </select>
                                }
                            </div>
                            <div className='flex flex-col m-[10px] mt-[5px] mb-[5px]'>
                                {
                                    flattenTypologies !== null &&
                                    <select className='select' onChange={handleTipologyChange} value={selectedTipology}>
                                        <option key={DEFAULT_TIPOLOGY} value={DEFAULT_TIPOLOGY}>Tipologia</option>
                                        {flattenTypologies.flat().map((typology, key) => {
                                            return (
                                                <option key={key} value={typology.value}>{typology.value}</option>
                                            )
                                        })}
                                    </select>
                                }
                            </div>
                            {
                                selectedContract === "Vendita" &&
                                <div className='flex flex-col m-[10px] mt-[5px] mb-[5px]'>
                                    {
                                        currentPriceRange !== null &&
                                        <select className='select' onChange={handlePriceRangeChange} value={selectedPriceRange}>
                                            <option key={DEFAULT_PRICE} value={DEFAULT_PRICE}>Fascia di prezzo</option>
                                            {currentPriceRange.map((price, key) => {
                                                return (
                                                    <option key={key} value={price.value}>{price.value}</option>
                                                )
                                            })}
                                        </select>
                                    }
                                </div>
                            }
                            {
                                selectedContract === "Locazione" &&
                                <div className='flex flex-col m-[10px] mt-[5px] mb-[5px]'>
                                    {
                                        currentLeaseRange !== null &&
                                        <select className='select' onChange={handleLeaseRangeChange} value={selectedLeaseRange}>
                                            <option key={DEFAULT_LEASE} value={DEFAULT_LEASE}>Periodo di locazione</option>
                                            {currentLeaseRange.map((lease, key) => {
                                                return (
                                                    <option key={key} value={lease.value}>{lease.value}</option>
                                                )
                                            })}
                                        </select>
                                    }
                                </div>
                            }
                            <div className='h-auto m-[10px]'>
                                <button className='btn-orange text-white font-bold pt-[5px] pb-[5px] pl-[10px] pr-[10px] rounded-[2px] text-[15px]' onClick={search}>CERCA</button>

                            </div>
                        </div >
                    </div>

                    {/* <InfiniteScroll
                        dataLength={currentResult.length}
                        next={fetchMore}
                        hasMore={hasMore}
                    >
                        <div className='flex flex-col xl:w-[850px] md:w-full w-full xl:mt[0px] md:mt-[10px] mt-[10px]' name="resultcontainer">
                            {currentResult.map((res) => {
                                return (
                                    <CercaItem on item={res} key={res.id} localita={selectedLocation} contract={selectedContract} tipology={selectedTipology} price={selectedPriceRange} lease={selectedLeaseRange} itemList={currentResult} />
                                )
                            })
                            }
                        </div>
                    </InfiniteScroll> */}
                    <div className='flex flex-col xl:w-[850px] md:w-full w-full xl:mt[0px] md:mt-[10px] mt-[10px]' name="resultcontainer">
                        {currentResult.map((res) => {
                            return (
                                <CercaItem id={res.id} on item={res} key={res.id} localita={selectedLocation} contract={selectedContract} tipology={selectedTipology} price={selectedPriceRange} lease={selectedLeaseRange} itemList={currentResult} haveMore={hasMore}
                                    index={res.id} />
                            )
                        })
                        }
                        {
                            hasMore &&
                            <div className='cursor-pointer btn-orange w-[50%] text-center ml-[25%] text-white font-bold pt-[5px] pb-[5px] pl-[10px] pr-[10px] rounded-[2px] text-[15px]' onClick={fetchMore}>Carica altri</div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Cerca;