import React from "react";
import MenuMobile from './MenuMobile'
import MenuDesktop from './MenuDesktop'
import logo from '../assets/logo.png';


const Header = () => {
    return (
        <div className='fixed bg-white z-1000'>
            <nav className='w-screen'>
                <div className='flex justify-center w-screen'>
                    <div className='xl:w-topbar-xl xl:h-topbar-xl xl:flex-row md:w-topbar-md md:h-topbar-md md:flex-row w-topbar-sm h-topbar-sm p-10px flex flex-col justify-between'>
                        <img alt="" src={logo} className='xl:w-logo-xl md:w-logo-md w-logo-sm'></img>
                        <div className='flex flex-col justify-between items-end'>
                            <div>
                                <a className='topbar-btn-amministrazione' href='https://www.google.com'>Amministrazione condomini</a>
                            </div>
                            <div className='flex flex-row'>
                                <a href="https://www.instagram.com/immobiliare_carnazzola?igsh=MXM2bHMyZTNtOXc2cg==" rel="noreferrer" target="_blank" className='topbar-lnk-social'>
                                    <i className="icofont-instagram" aria-hidden="true"></i>
                                </a>
                                <a href="https://www.facebook.com/IMMOBILIARE-CARNAZZOLA-CLARA-238099856238932/" rel="noreferrer" target="_blank" className='topbar-lnk-social'>
                                    <i className="icofont-facebook" aria-hidden="true"></i>
                                </a>
                                <a href="tel:0342452491" rel="noreferrer" target="_blank" className='topbar-lnk-social'>
                                    <i className="icofont-phone" aria-hidden="true"></i>
                                </a>
                                <a href="tel:3478528235" rel="noreferrer" target="_blank" className='topbar-lnk-social'>
                                    <i className="icofont-iphone" aria-hidden="true"></i>
                                </a>
                                <a href="mailto:info@immobiliarecarnazzola.com" rel="noreferrer" target="_blank" className='topbar-lnk-social'>
                                    <i className="icofont-email" aria-hidden="true"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
            <MenuDesktop />
            <MenuMobile />
        </div>
    )
}

export default Header