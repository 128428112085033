import React, {useEffect} from 'react'
import SearchBar from './SearchBar'

const Servizi = () => {
    useEffect(() => {
            setTimeout(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, 50);
        }, [])

    return (
        <div className='flex  xl:flex-row flex-col justify-center items-center w-full mb-[30px] xl:items-start xl:mt-[30px]'>
            <div className='flex flex-col xl:mt-0 md:mt-[20px] mt-[20px] xl:w-[800px] md:w-[750px] w-[350px]'>
                <div className='font-bold ml-[20px] mr-[20px] text-xl text-claim border-t-[3px] border-t-site-lite-orange border-b-[3px] border-b-site-lite-orange m-[10px]'>
                    I servizi offerti da Immobiliare Carnazzola Clara
                </div>
                <div className='ml-[20px] mr-[20px] pt-[5px] pb-[5px]' >
                    <div className='flex flex-row m-[10px] text-[15px] text-claim items-center'>
                        <div className='servizi-icon'>
                            <i className="icofont-euro ml-[-7px]"></i>
                        </div>
                        <div className='ml-[20px] text-claim text-base text-justify'>
                            <p className='font-bold text-base mb-[5px]'>Valutazione gratuita con verifica valore di mercato</p>
                            <p className='text-justify'>Cerchiamo per la nostra clientela immobili da vendere e/o da affittare. Offriamo consulenza e valutazione gratuite degli immobili con verifica del valore di mercato</p>
                        </div>
                    </div>
                </div>
                <div className='ml-[20px] mr-[20px] pt-[5px] pb-[5px]' >
                    <div className='flex flex-row m-[10px] text-[15px] text-claim items-center'>
                        <div className='servizi-icon'>
                            <i className="icofont-search-property"></i>
                        </div>
                        <div className='ml-[20px] text-claim text-base text-justify'>
                            <p className='font-bold text-base mb-[5px]'>Cerchiamo per te</p>
                            <p>Cerchiamo per te l’immobile che desideri</p>
                        </div>
                    </div>
                </div>
                <div className='ml-[20px] mr-[20px] pt-[5px] pb-[5px]' >
                    <div className='flex flex-row m-[10px] text-[15px] text-claim items-center'>
                        <div className='servizi-icon'>
                            <i className="icofont-building-alt"></i>
                        </div>
                        <div className='ml-[20px] text-claim text-base text-justify'>
                            <p className='font-bold text-base mb-[5px]'>Amministriamo condomini</p>
                            <p>Soci A.N.AMM.I. n. S494 e BA89</p>
                        </div>
                    </div>
                </div>
                <div className='ml-[20px] mr-[20px] pt-[5px] pb-[5px]' >
                    <div className='flex flex-row m-[10px] text-[15px] text-claim items-center'>
                        <div className='servizi-icon'>
                            <i className="icofont-paper"></i>
                        </div>
                        <div className='ml-[20px] text-claim text-base text-justify'>
                            <p className='font-bold text-base mb-[5px]'>Locazione</p>
                            <p>Verifica inquilini, stesura contratto e registrazione, gestione pagamenti, conteggio spese, assistenza in loco con reperibilità</p>
                        </div>
                    </div>
                </div>
                <div className='ml-[20px] mr-[20px] pt-[5px] pb-[5px]' >
                    <div className='flex flex-row m-[10px] text-[15px] text-claim items-center'>
                        <div className='servizi-icon'>
                            <i className="icofont-papers"></i>
                        </div>
                        <div className='ml-[20px] text-claim text-base text-justify'>
                            <p className='font-bold text-base mb-[5px]'>Vendita</p>
                            <p>Stesura e registrazione contratti preliminari, predisposizione atti notarili, verifica conformità urbanistica e catastale, ispezioni ipotecarie</p>
                        </div>
                    </div>
                </div>
            </div>
            <SearchBar />
        </div>
    )
}

export default Servizi