import React, { useEffect } from 'react'

const Contatti = () => {
    useEffect(() => {

        setTimeout(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, 50);
    }, [])

    return (
        <div className='flex xl:flex-row flex-col justify-center xl:items-start items-center w-full mb-[30px] xl:mt-[30px]'>
            <div className='flex flex-col xl:mt-0 md:mt-[20px] mt-[20px] xl:w-[800px] md:w-[750px] w-[350px]'>
                <div className='font-bold ml-[20px] mr-[20px] text-xl text-claim border-t-[3px] border-t-site-lite-orange border-b-[3px] border-b-site-lite-orange m-[10px]'>
                    Dove siamo
                </div>
                <div className='ml-[20px] mr-[20px] m-[10px]'>
                    <iframe className="imap h-[420px] w-full" title='dovesiamo' scrolling="no" src="https://maps.google.it/maps?f=d&amp;source=s_d&amp;saddr=Via+Ezio+Vanoni,+33,+23020+Caspoggio+SO&amp;daddr=&amp;hl=it&amp;geocode=CbnTlyQcRXaWFUTuwQIdS32WACmZJe8floWDRzHryYt_5WfM_g&amp;mra=mi&amp;sll=46.263621,9.862633&amp;sspn=0.020175,0.038581&amp;ie=UTF8&amp;ll=46.263621,9.862633&amp;spn=0.020175,0.038581&amp;output=embed"></iframe>
                </div>
            </div>
            <div className='flex flex-col bg-site-light-grey2 ml-[20px] mr-[20px] pt-[5px] pb-[5px] xl:w-[350px] md:w-[720px] w-[320px]'>
                <div className='font-bold text-xl text-claim border-t-[3px] border-t-site-lite-orange border-b-[3px] border-b-site-lite-orange m-[10px]'>
                    Contatti
                </div>
                <div className='m-[10px] text-claim text-lg font-bold'>
                    Indirizzo
                </div>
                <div className='mr-[10px] ml-[10px] mb-[10px] text-clip text-sm'>
                    <p>Immobiliare Carnazzola Clara</p>
                    <p>Via Vanoni, 33</p>
                    <p>23020 Caspoggio (SO)</p>
                </div>
                <div className='m-[10px] text-claim text-lg font-bold'>
                    Telefono
                </div>
                <div className='mr-[10px] ml-[10px] mb-[10px]'>
                    <a href="tel:0342452491" className="lnk-gen">0342 45 24 91</a>
                </div>
                <div className='m-[10px] text-claim text-lg font-bold'>
                    Mail
                </div>
                <div className='mr-[10px] ml-[10px] mb-[10px]'>
                    <a href="mailto:info@immobiliarecarnazzola.com" className="lnk-gen">info@immobiliarecarnazzola.com</a>
                </div>
                <div className='m-[10px] text-claim text-lg font-bold'>
                    P. Iva
                </div>
                <div className='mr-[10px] ml-[10px] mb-[10px] text-clip text-sm'>
                    00862930146
                </div>
                <div className='m-[10px] text-claim text-lg font-bold'>
                    CF
                </div>
                <div className='mr-[10px] ml-[10px] mb-[10px] text-clip text-sm'>
                    CRNCLR69S62I829G
                </div>
            </div>
        </div>
    )
}

export default Contatti