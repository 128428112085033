import { useContext, useEffect, useState } from 'react'
import { useForm, useFormState } from "react-hook-form";

import { FirestoreContext } from './FirestoreProvider';
import 'react-datepicker/dist/react-datepicker.css';
import { v4 as uuidv4 } from 'uuid'
import Toast from './Toast';
import AddImmobileImage from './AddImmobileImage';
import AddImmobileFasciaLocazioneItem from './AddImmobileFasciaLocazioneItem';

const AddImmobile = ({ showAddImmobile, reloadBuilding, newItem = true, editBuilding = null }) => {

    const immobile = {
        "key": "",
        "contract": { "value": "", "visible": true },
        "city": { "value": "", "visible": true },
        "indirizzo": { "value": "", "visible": true },
        "prezzo": { "value": "", "visible": true },
        "classeEnergetica": { "value": "", "visible": true },
        "locali": { "value": null, "visible": true },
        "superficie": { "value": null, "visible": true },
        "bagni": { "value": null, "visible": true },
        "riscaldamento": { "value": "", "visible": true },
        "terrazzo": { "value": null, "visible": true },
        "piano": { "value": "", "visible": true },
        "pianiTotali": { "value": null, "visible": true },
        "parcheggio": { "value": null, "visible": true },
        "box": { "value": null, "visible": true },
        "ascensore": { "value": null, "visible": true },
        "cantina": { "value": null, "visible": true },
        "giardino": { "value": null, "visible": true },
        "statoImmobile": { "value": "", "visible": true },
        "mobili": { "value": "", "visible": true },
        "terra": { "value": null, "visible": true },
        "descrizione": { "value": "", "visible": true },
        "tipologia": { "value": [], "visible": true },
        "visible": { "value": false, "visible": true },
        "vetrina": { "value": false, "visible": true },
        "fasciaVendita": { "value": null, "visible": true },
        "fasciaLocazione": { "value": null, "visible": true },
        // "prenotabileDa": { "value": null, "visible": true },
        // "prenotabileFino": { "value": null, "visible": true },
        "link": { "value": null, "visible": true }
    };

    // const { getBuildings } = useContext(FirestoreContext);
    const { locations, getLocations } = useContext(FirestoreContext);
    const { uploadImage, deleteImage } = useContext(FirestoreContext);
    const { contracts, retrieveContracts } = useContext(FirestoreContext);
    const { priceRange, getPriceRange } = useContext(FirestoreContext);
    const { leaseRange, getLeaseRange } = useContext(FirestoreContext);
    const { flattenTypologies, retrieveFlattenTypologies } = useContext(FirestoreContext);
    const { insertBuilding, updateBuilding, updateBuildingImages } = useContext(FirestoreContext);
    const [isError, setIsError] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [currentImmobile, setCurrentImmobile] = useState(immobile);
    const [loading, setLoading] = useState(false);
    const [showImages, setShowImages] = useState(false);

    const showLoader = (show) => {
        setLoading(show);
    }

    // #region eyes
    const [showIndirizzo, setShowIndirizzo] = useState(true);
    const indirizzoShow = () => {
        setShowIndirizzo(!showIndirizzo);
        immobile.indirizzo.visible = !immobile.indirizzo.visible;
    }

    const [showTipoContratto, setShowTipoContratto] = useState(true);
    const tipoContrattoShow = () => {
        setShowTipoContratto(!showTipoContratto);
    }

    const [showFasciaVendita, setShowFasciaVendita] = useState(true);
    const fasciaVenditaShow = () => {
        setShowFasciaVendita(!showFasciaVendita);
    }

    const [showFasciaLocazione, setShowFasciaLocazione] = useState(true);
    const fasciaLocazioneShow = () => {
        setShowFasciaLocazione(!showFasciaLocazione);
    }

    // const [showPrenotabileDa, setShowPrenotabileDa] = useState(true);
    // const prenotabileDaShow = () => {
    //     setShowPrenotabileDa(!showPrenotabileDa);
    // }

    // const [showPrenotabileFino, setShowPrenotabileFino] = useState(true);
    // const prenotabileFinoShow = () => {
    //     setShowPrenotabileFino(!showPrenotabileFino);
    // }

    const [showTipologia, setShowTipologia] = useState(true);
    const tipologiaShow = () => {
        setShowTipologia(!showTipologia);
    }

    const [showCitta, setShowCitta] = useState(true);
    const cittaShow = () => {
        setShowCitta(!showCitta);
    }

    const [showPrezzo, setShowPrezzo] = useState(true);
    const prezzoShow = () => {
        setShowPrezzo(!showPrezzo);
    }

    const [showClasseEnergetica, setShowClasseEnergetica] = useState(true);
    const classeEnergeticaShow = () => {
        setShowClasseEnergetica(!showClasseEnergetica);
    }

    const [showLocali, setShowLocali] = useState(true);
    const localiShow = () => {
        setShowLocali(!showLocali);
    }

    const [showSuperficie, setShowSuperficie] = useState(true);
    const superficieShow = () => {
        setShowSuperficie(!showSuperficie);
    }

    const [showBagni, setShowBagni] = useState(true);
    const bagniShow = () => {
        setShowBagni(!showBagni);
    }

    const [showRiscaldamento, setShowRiscaldamento] = useState(true);
    const riscaldamentoShow = () => {
        setShowRiscaldamento(!showRiscaldamento);
    }

    const [showTerrazzo, setShowTerrazzo] = useState(true);
    const terrazzoShow = () => {
        setShowTerrazzo(!showTerrazzo);
    }

    const [showPiano, setShowPiano] = useState(true);
    const pianoShow = () => {
        setShowPiano(!showPiano);
    }

    const [showPianiTotali, setShowPianiTotali] = useState(true);
    const pianiTotaliShow = () => {
        setShowPianiTotali(!showPianiTotali);
    }

    const [showParcheggio, setShowParcheggio] = useState(true);
    const parcheggioShow = () => {
        setShowParcheggio(!showParcheggio);
    }

    const [showBox, setShowBox] = useState(true);
    const boxShow = () => {
        setShowBox(!showBox);
    }

    const [showAscensore, setShowAscensore] = useState(true);
    const ascensoreShow = () => {
        setShowAscensore(!showAscensore);
    }

    const [showCantina, setShowCantina] = useState(true);
    const cantinaShow = () => {
        setShowCantina(!showCantina);
    }

    const [showGiardino, setShowGiardino] = useState(true);
    const giardinoShow = () => {
        setShowGiardino(!showGiardino);
    }

    const [showStatoImmobile, setShowStatoImmobile] = useState(true);
    const statoImmobileShow = () => {
        setShowStatoImmobile(!showStatoImmobile);
    }

    const [showMobili, setShowMobili] = useState(true);
    const mobiliShow = () => {
        setShowMobili(!showMobili);
    }

    const [showTerra, setShowTerra] = useState(true);
    const terraShow = () => {
        setShowTerra(!showTerra);
    }

    const [showDescrizione, setShowDescrizione] = useState(true);
    const descrizioneShow = () => {
        setShowDescrizione(!showDescrizione);
    }

    const [showLink, setShowLink] = useState(true);
    const linkShow = () => {
        setShowLink(!showLink)
    }

    const [showVisibile, setShowVisibile] = useState(false);
    const [showVetrina, setShowVetrina] = useState(false);
    // #endregion

    // #region values
    const { handleSubmit } = useForm();
    const handleForm = async (data) => {
        currentImmobile.contract.visible = showTipoContratto;

        currentImmobile.city.visible = showCitta;

        currentImmobile.indirizzo.visible = showIndirizzo;

        currentImmobile.prezzo.visible = showPrezzo;

        currentImmobile.classeEnergetica.visible = showClasseEnergetica;

        currentImmobile.locali.visible = showLocali;

        currentImmobile.superficie.visible = showSuperficie;

        currentImmobile.bagni.visible = showBagni;

        currentImmobile.riscaldamento.visible = showRiscaldamento;

        currentImmobile.terrazzo.visible = showTerrazzo;

        currentImmobile.piano.visible = showPiano;

        currentImmobile.pianiTotali.visible = showPianiTotali;

        currentImmobile.parcheggio.visible = showParcheggio;

        currentImmobile.box.visible = showBox;

        currentImmobile.ascensore.visible = showAscensore;

        currentImmobile.cantina.visible = showCantina;

        currentImmobile.giardino.visible = showGiardino;

        currentImmobile.statoImmobile.visible = showStatoImmobile;

        currentImmobile.mobili.visible = showMobili;

        currentImmobile.terra.visible = showTerra;

        currentImmobile.descrizione.visible = showDescrizione;

        currentImmobile.tipologia.visible = true;

        currentImmobile.visible.visible = showVisibile;

        currentImmobile.vetrina.visible = showVetrina;

        currentImmobile.fasciaVendita.visible = showFasciaVendita;

        currentImmobile.fasciaLocazione.visible = showFasciaLocazione;

        // currentImmobile.prenotabileDa.value = locationDateFrom ?? null;
        // currentImmobile.prenotabileDa.visible = showPrenotabileDa;

        // currentImmobile.prenotabileFino.value = locationDateTo ?? null;
        // currentImmobile.prenotabileFino.visible = showPrenotabileFino;

        currentImmobile.link.value = link;
        currentImmobile.link.visible = showLink;

        setCurrentImmobile(currentImmobile);

        if (newItem) {
            insertImmobile();
        } else {
            updateImmobile();
        }

    }

    const [indirizzo, setIndirizzo] = useState('');
    const [city, setCity] = useState('');
    const [prezzo, setPrezzo] = useState('');
    const [classeEnergetica, setClasseEnergetica] = useState('');
    const [locali, setLocali] = useState('');
    const [superficie, setSuperficie] = useState('');
    const [bagni, setBagni] = useState('');
    const [riscaldamento, setRiscaldamento] = useState('');
    const [terrazzo, setTerrazzo] = useState('');
    const [piano, setPiano] = useState('');
    const [pianiTotali, setPianiTotali] = useState('');
    const [parcheggio, setParcheggio] = useState('');
    const [box, setBox] = useState(false);
    const [ascensore, setAscensore] = useState(false);
    const [cantina, setCantina] = useState(false);
    const [giardino, setGiardino] = useState('');
    const [statoImmobile, setStatoImmobile] = useState('');
    const [mobili, setMobili] = useState('');
    const [terra, setTerra] = useState('');
    const [descrizione, setDescrizione] = useState('');
    const [tipologia, setTipology] = useState([]);
    const [visible, setVisible] = useState(false);
    const [vetrina, setVetrina] = useState(false);
    const [fasciaVendita, setFasciaVendita] = useState('');
    const [fasciaLocazione, setFasciaLocazione] = useState([]);
    const [link, setLink] = useState('');

    const spreadCurrentBuildg = () => {
        setContract(editBuilding.contract?.value ?? "");
        setShowTipoContratto(editBuilding.contract.visible);

        setCity(editBuilding.city?.value ?? "");
        setShowCitta(editBuilding.city.visible)

        setIndirizzo(editBuilding.indirizzo?.value ?? "");
        setShowIndirizzo(editBuilding.indirizzo.visible);

        setPrezzo(editBuilding.prezzo?.value ?? "");
        setShowPrezzo(editBuilding.prezzo.visible);

        setClasseEnergetica(editBuilding.classeEnergetica?.value ?? "");
        setShowClasseEnergetica(editBuilding.classeEnergetica.visible);

        setLocali(editBuilding.locali?.value ?? "");
        setShowLocali(editBuilding.locali.visible);

        setSuperficie(editBuilding.superficie?.value ?? "");
        setShowSuperficie(editBuilding.superficie.visible);

        setBagni(editBuilding.bagni?.value ?? "");
        setShowBagni(editBuilding.bagni.visible);

        setRiscaldamento(editBuilding.riscaldamento?.value ?? "");
        setShowRiscaldamento(editBuilding.riscaldamento.visible);

        setTerrazzo(editBuilding.terrazzo?.value ?? "");
        setShowTerrazzo(editBuilding.terrazzo.visible);

        setPiano(editBuilding.piano?.value ?? "");
        setShowPiano(editBuilding.piano.visible);

        setPianiTotali(editBuilding.pianiTotali?.value ?? "");
        setShowPianiTotali(editBuilding.pianiTotali.visible);

        setParcheggio(editBuilding.parcheggio?.value ?? "");
        setShowParcheggio(editBuilding.parcheggio.visible);

        setBox(editBuilding.box?.value ?? false);
        setShowBox(editBuilding.box.visible);

        setAscensore(editBuilding.ascensore?.value ?? false);
        setShowAscensore(editBuilding.ascensore.visible);

        setCantina(editBuilding.cantina?.value ?? false);
        setShowCantina(editBuilding.cantina.visible);

        setGiardino(editBuilding.giardino?.value ?? "");
        setShowGiardino(editBuilding.giardino.visible);

        setStatoImmobile(editBuilding.statoImmobile?.value ?? "");
        setShowStatoImmobile(editBuilding.statoImmobile.visible);

        setMobili(editBuilding.mobili?.value ?? "");
        setShowMobili(editBuilding.mobili.visible);

        setTerra(editBuilding.terra?.value ?? "");
        setShowTerra(editBuilding.terra.visible);

        setDescrizione(editBuilding.descrizione?.value ?? "");
        setShowDescrizione(editBuilding.descrizione.visible);

        setTipology(editBuilding.tipologia?.value ?? []);
        setShowTipologia(editBuilding.tipologia.visible);

        setVisible(editBuilding.visible?.value ?? false);
        setShowVisibile(editBuilding.visible.visible);

        setVetrina(editBuilding.vetrina?.value ?? false);
        setShowVetrina(editBuilding.vetrina.visible);

        setFasciaVendita(editBuilding.fasciaVendita?.value ?? "");
        setShowFasciaVendita(editBuilding.fasciaVendita.visible);

        setFasciaLocazione(editBuilding.fasciaLocazione?.value ?? []);
        setShowFasciaLocazione(editBuilding.fasciaLocazione.visible);

        setLink(editBuilding.link?.value ?? "");
        setShowLink(editBuilding.link?.visible ?? false);


        // if (!!editBuilding.prenotabileDa.value) {
        //     setLocationDateFrom(new Date(editBuilding.prenotabileDa.value.seconds * 1000));
        // }
        // setShowPrenotabileDa(editBuilding.prenotabileDa.visible);

        // if (!!editBuilding.prenotabileFino.value) {
        //     setLocationDateTo(new Date(editBuilding.prenotabileFino.value.seconds * 1000));
        // }
        // setShowPrenotabileFino(editBuilding.prenotabileFino.visible);

        let appImmobile = { ...editBuilding };
        setCurrentImmobile(appImmobile);

        if (!!editBuilding.images) {
            setSelectedImages([...editBuilding.images]);
        }

    }

    const handleChange = (e) => {
        let appImmobile = { ...currentImmobile };
        switch (e.target.name) {
            case 'contract':
                setContract(e.target.value);
                appImmobile.contract.value = e.target.value;
                appImmobile.fasciaVendita.value = null;
                setFasciaVendita("");
                appImmobile.fasciaLocazione.value = [];
                setFasciaLocazione([]);
                break;
            case 'city':
                setCity(e.target.value);
                appImmobile.city.value = e.target.value;
                break;
            case 'indirizzo':
                setIndirizzo(e.target.value);
                appImmobile.indirizzo.value = e.target.value;
                break;
            case 'prezzo':
                setPrezzo(e.target.value);
                appImmobile.prezzo.value = e.target.value;
                break;
            case 'classeEnergetica':
                setClasseEnergetica(e.target.value);
                appImmobile.classeEnergetica.value = e.target.value;
                break;
            case 'locali':
                setLocali(e.target.value);
                appImmobile.locali.value = e.target.value;
                break;
            case 'superficie':
                setSuperficie(e.target.value);
                appImmobile.superficie.value = e.target.value;
                break;
            case 'bagni':
                setBagni(e.target.value);
                appImmobile.bagni.value = e.target.value;
                break;
            case 'riscaldamento':
                setRiscaldamento(e.target.value);
                appImmobile.riscaldamento.value = e.target.value;
                break;
            case 'terrazzo':
                setTerrazzo(e.target.value);
                appImmobile.terrazzo.value = e.target.value;
                break;
            case 'piano':
                setPiano(e.target.value);
                appImmobile.piano.value = e.target.value;
                break;
            case 'pianiTotali':
                setPianiTotali(e.target.value);
                appImmobile.pianiTotali.value = e.target.value;
                break;
            case 'parcheggio':
                setParcheggio(e.target.value);
                appImmobile.parcheggio.value = e.target.value;
                break;
            case 'box':
                setBox(e.target.checked);
                appImmobile.box.value = e.target.checked;
                break;
            case 'ascensore':
                setAscensore(e.target.checked);
                appImmobile.ascensore.value = e.target.checked;
                break;
            case 'cantina':
                setCantina(e.target.checked);
                appImmobile.cantina.value = e.target.checked;
                break;
            case 'giardino':
                setGiardino(e.target.value);
                appImmobile.giardino.value = e.target.value;
                break;
            case 'statoImmobile':
                setStatoImmobile(e.target.value);
                appImmobile.statoImmobile.value = e.target.value;
                break;
            case 'mobili':
                setMobili(e.target.value);
                appImmobile.mobili.value = e.target.value;
                break;
            case 'terra':
                setTerra(e.target.value);
                appImmobile.terra.value = e.target.value;
                break;
            case 'descrizione':
                setDescrizione(e.target.value);
                appImmobile.descrizione.value = e.target.value;
                break;
            case 'tipologia':
                if (e.target.value !== "defaultTipologia") {
                    if (!tipologia?.includes(e.target.value) ?? true) {
                        let app = [...tipologia ?? []];
                        app.push(e.target.value.replace("-", ""));
                        setTipology(app);
                        appImmobile.tipologia.value = app;
                    }
                }
                break;
            case 'visible':
                setVisible(e.target.checked);
                appImmobile.visible.value = e.target.checked;
                break;
            case 'vetrina':
                setVetrina(e.target.checked);
                appImmobile.vetrina.value = e.target.checked;
                break;
            case 'fasciaVendita':
                setFasciaVendita(e.target.value);
                appImmobile.fasciaVendita.value = e.target.value;
                break;
            case 'fasciaLocazione':
                if (e.target.value !== "defaultFasciaLocazione") {
                    if (!fasciaLocazione?.includes(e.target.value) ?? true) {
                        let app = [...fasciaLocazione ?? []];
                        app.push(e.target.value);
                        setFasciaLocazione(app);
                        appImmobile.fasciaLocazione.value = app;
                    }
                }
                break;
            case 'link':
                setLink(e.target.value);
                if (!!appImmobile.link === false) {
                    appImmobile["link"] = {
                        "value": e.target.value,
                        "visible": false
                    }
                } else {
                    appImmobile.link.value = e.target.value;
                }
                break;
            default:
                break;
        }

        setCurrentImmobile(appImmobile);
    }

    const insertImmobile = async () => {
        currentImmobile.key = uuidv4();
        await insertBuilding(currentImmobile)
            .then((res) => {
                setMessage(false, "Inserimento avvenuto con successo");
                setCurrentImmobile(currentImmobile);
                // getBuildings();
                // showAddImmobile();
            })
            .catch((err) => {
                console.log(err);
                setMessage(true, "Errore durante l'inserimento");
            })
    }

    const updateImmobile = async () => {
        await updateBuilding(currentImmobile)
            .then((res) => {
                setMessage(false, "Aggiornamento avvenuto con successo");
                // getBuildings();
                // showAddImmobile();
            })
            .catch((err) => {
                console.log(err);
                setMessage(true, "Errore durante l'aggiornamento");
            })
    }

    const [contract, setContract] = useState("");
    // const handleContractChange = (e) => {
    //     setContract(e.target.value);
    // }

    // const [locationDateFrom, setLocationDateFrom] = useState(null);
    // const handleChangeLocationDateFrom = date => {
    //     if (!!date) {
    //         date.setHours(0);
    //         date.setMinutes(0);
    //         date.setSeconds(0);
    //         setLocationDateFrom(date);
    //     }
    // }

    // const [locationDateTo, setLocationDateTo] = useState(null);
    // const handleChangeLocationDateTo = date => {
    //     if (!!date) {
    //         date.setHours(23);
    //         date.setMinutes(59);
    //         date.setSeconds(59);
    //         setLocationDateTo(date);
    //     }
    // }

    // #endregion

    // #region images
    const [selectedImages, setSelectedImages] = useState([]);
    const handleImageChange = async (e) => {
        showLoader(true);

        var newImages = [];
        [...e.target.files].forEach((f) => {
            const imageFile = f;

            if (!!imageFile == null) {
                return;
            }

            const fileSizeMB = imageFile.size / (1024 * 1024);

            // if (fileSizeMB > 5) {
            //     setMessage(true, "Immagine troppo grande. Dimensione massima 5MB");
            //     return;
            // }

            const extention = imageFile.name.split('.').pop();
            const imageID = currentImmobile.idAnnuncio + "_" + uuidv4();

            const file = new File([imageFile], imageID + "." + extention);

            const image = {
                id: imageID,
                url: "",
                name: file.name,
                order: 0,
                cover: false,
                error: (fileSizeMB > 5) ? "Immagine troppo grande. Dimensione massima 5MB" : "",
                f: file
            }
            newImages.push(image);
            setShowImages(true);
        });


        await localUploadImage([...newImages])
            .then(async (imagesApp) => {
                let appImmobile = { ...currentImmobile };
                let onlyNoError = imagesApp.filter(img => img.error === "");
                let toUpdate = [...selectedImages];
                onlyNoError.forEach(noError => {
                    toUpdate.push(noError);
                })
                await updateBuildingImages(appImmobile.id, toUpdate)
                    .then((success) => {
                        appImmobile["images"] = [...toUpdate];
                        setCurrentImmobile(appImmobile);
                        let imgToShow = [...selectedImages];
                        imagesApp.forEach(img => {
                            imgToShow.push(img);
                        })
                        setSelectedImages(imgToShow);
                        setMessage(false, "Caricamento avvenuto con successo");
                    })
                    .catch((err) => {
                        setMessage(true, "Errore durante il caricamento dell'immagine");
                    });
            });
        showLoader(false);
    }

    const localUploadImage = async (images) => {

        let imagesApp = [];
        for (let index = 0; index < images.length; index++) {
            const img = images[index];

            if (img.error === "") {
                await uploadSingleImage(img)
                    .then((img) => {
                        imagesApp.push(img);
                    })
            } else {
                delete img.f;
                imagesApp.push(img);
            }
        }

        return imagesApp;
    }

    const uploadSingleImage = async (img) => {
        var p = new Promise(async (resolve) => {
            await uploadImage(img.f)
                .then((url) => {
                    img.url = url
                    delete img.f;
                    resolve(img);
                })
                .catch((err) => {
                    img.error = "Errore durante il caricamento dell'immagine"
                    resolve(img);
                })
        });
        return p;
    }

    const callBackOrder = async (image) => {
        let appImages = selectedImages.filter(item => item.url !== image.url);

        appImages.push(image);

        const newImages = appImages.sort((a, b) => a.position - b.position);

        setSelectedImages(newImages);
        currentImmobile.images = [...newImages];
        setCurrentImmobile(currentImmobile);
    }

    const callBackDeleteImage = async (image) => {
        let newImages = selectedImages.filter(item => item.url !== image.url);
        setSelectedImages(newImages);
        currentImmobile.images = [...newImages];
        setCurrentImmobile(currentImmobile);

        await deleteImage(image.name)
            .then(async () => {
                localUpdateBuildingImages(newImages)
            })
            .catch(() => {
                localUpdateBuildingImages(newImages);
                setMessage(true, "Errore durante la cancellazione dell'immagine")
            })
    }

    const callbackHandleCover = async (image) => {
        let newImages = selectedImages.filter(item => item.id !== image.id);
        let previousCover = newImages.filter(item => item.cover === true);
        newImages = newImages.filter(item => item.cover !== true);
        if (previousCover.length > 0) {
            let cover = previousCover[0];
            cover.cover = false;
            newImages.push(cover);
        }
        newImages.push(image);
        newImages = newImages.sort((a, b) => a.order - b.order);
        setSelectedImages(newImages);
        currentImmobile.images = [...newImages];
        setCurrentImmobile(currentImmobile);

        await updateBuildingImages(editBuilding.id, newImages);
    }

    const localUpdateBuildingImages = async (newImages) => {
        await updateBuildingImages(editBuilding.id, newImages)
            .then(() => {
                setMessage(false, "Immagine cancellata con successo")
            })
            .catch(() => {
                setMessage(true, "Immagine cancellata su archivio ma non annuncio")
            })
    }

    const saveImagesOrder = async () => {
        await updateBuildingImages(editBuilding.id, selectedImages)
            .then((success) => {
                setMessage(false, "Ordinamento salvato correttamente")
            })
            .catch((err) => {
                setMessage(true, "Errore durante la cancellazione dell'immagine")
            });
    }

    // #endregion

    const setMessage = (isError, message) => {
        setToastMessage(message);
        setIsError(isError);
        setShowToast(true);
    }

    const onCloseToast = () => {
        setShowToast(false);
    }

    const closeAddImmobile = () => {
        showAddImmobile();
    }

    const handleRemoveLocazione = (fascia) => {
        const newFasceLocazione = fasciaLocazione.filter(item => item !== fascia);
        setFasciaLocazione(newFasceLocazione);
        currentImmobile.fasciaLocazione.value = [...newFasceLocazione];
        setCurrentImmobile(currentImmobile);
    }

    const handleRemovetipologia = (name) => {
        const newTipologie = tipologia.filter(item => item !== name);
        setTipology(newTipologie);
        currentImmobile.tipologia.value = [...newTipologie];
        setCurrentImmobile(currentImmobile);
    }

    const [title, setTitle] = useState("AGGIUNGI IMMOBILE");

    useEffect(() => {
        getLocations();
        retrieveContracts();
        retrieveFlattenTypologies();
        getPriceRange();
        getLeaseRange();

        if (!newItem) {
            spreadCurrentBuildg();
            setTitle("MODIFICA IMMOBILE: " + editBuilding.idAnnuncio);
        }

    }, [])

    const clickShowImages = () => {
        setShowImages(true);
    }

    return (
        <>
            {
                loading && <div className="overlay"><div className="spinner"></div></div>
            }
            {showToast &&
                <Toast isError={isError} message={toastMessage} duration={3000} whenClose={onCloseToast} />
            }
            <div className="add-building-overlay">
                <div className="add-building-popup">
                    <div className="w-full flex flex-col">
                        <div className="flex flex-row">
                            <h3 className='text-claim font-bold mb-[20px] w-[90%]'>{title}</h3>
                            <div className="w-[10%] text-right text-[25px] cursor-pointer" onClick={closeAddImmobile}>
                                <i className="text-claim icofont-ui-close"></i>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit(handleForm)}>
                            <div className="grid grid-cols-2 grid-rows-31 gap-4">
                                <div className="add-building-popup-lbl">TIPO CONTRATTO</div>
                                <div className="add-building-popup-lbl">CITTA'</div>
                                <div className="flex flex-row">
                                    <div className="w-[5%] h-[35px] bg-site-light-grey3 text-center p-[5px] rounded cursor-pointer" onClick={tipoContrattoShow}>
                                        {showTipoContratto ? <i className="icofont-eye-alt"></i> : <i className="icofont-eye-blocked"></i>}
                                    </div>
                                    <select className='select2 w-[95%] ml-[10px]' name='contract' value={contract} onChange={handleChange}>
                                        <option key="defaultContract" value="defaultContract">Contratto</option>
                                        {contracts.map((contract) => {
                                            return (
                                                <option key={contract.key} value={contract.value}>{contract.value}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="flex flex-row">
                                    <div className="w-[5%] h-[35px] bg-site-light-grey3 text-center p-[5px] rounded cursor-pointer" onClick={cittaShow}>
                                        {showCitta ? <i className="icofont-eye-alt"></i> : <i className="icofont-eye-blocked"></i>}
                                    </div>
                                    <select className='select2 w-[95%] ml-[10px]' value={city} onChange={handleChange} name='city'>
                                        <option key="defaultLocation" value="defaultLocation">Località</option>
                                        {locations.map((location) => {
                                            return (
                                                <option key={location.key} value={location.value}>{location.value}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="add-building-popup-lbl">INDIRIZZO</div>
                                <div className="add-building-popup-lbl">PREZZO</div>
                                <div className="flex flex-row">
                                    <div className="w-[5%] h-[35px] bg-site-light-grey3 text-center p-[5px] rounded cursor-pointer" onClick={indirizzoShow}>
                                        {showIndirizzo ? <i className="icofont-eye-alt"></i> : <i className="icofont-eye-blocked"></i>}
                                    </div>
                                    <input className="input-text w-[95%] ml-[10px]" type="text" placeholder="Indirizzo" value={indirizzo} onChange={handleChange} name='indirizzo' />
                                </div>
                                <div className="flex flex-row">
                                    <div className="w-[5%] h-[35px] bg-site-light-grey3 text-center p-[5px] rounded cursor-pointer" onClick={prezzoShow}>
                                        {showPrezzo ? <i className="icofont-eye-alt"></i> : <i className="icofont-eye-blocked"></i>}
                                    </div>
                                    <input className="input-text w-[95%] ml-[10px]" type="text" placeholder="Prezzo" name="prezzo" value={prezzo} onChange={handleChange} />
                                </div>
                                <div className="add-building-popup-lbl">CLASSE ENERGETICA</div>
                                <div className="add-building-popup-lbl">LOCALI</div>
                                <div className="flex flex-row">
                                    <div className="w-[5%] h-[35px] bg-site-light-grey3 text-center p-[5px] rounded cursor-pointer" onClick={classeEnergeticaShow}>
                                        {showClasseEnergetica ? <i className="icofont-eye-alt"></i> : <i className="icofont-eye-blocked"></i>}
                                    </div>
                                    <input className="input-text w-[95%] ml-[10px]" type="text" placeholder="Classe Energetica" name="classeEnergetica" value={classeEnergetica} onChange={handleChange} />
                                </div>
                                <div className="flex flex-row">
                                    <div className="w-[5%] h-[35px] bg-site-light-grey3 text-center p-[5px] rounded cursor-pointer" onClick={localiShow}>
                                        {showLocali ? <i className="icofont-eye-alt"></i> : <i className="icofont-eye-blocked"></i>}
                                    </div>
                                    <input className="input-text w-[95%] ml-[10px]" type="number" step="1" min="0" placeholder="Locali" name="locali" value={locali} onChange={handleChange} />
                                </div>
                                <div className="add-building-popup-lbl">SUPERFICIE</div>
                                <div className="add-building-popup-lbl">BAGNI</div>
                                <div className="flex flex-row">
                                    <div className="w-[5%] h-[35px] bg-site-light-grey3 text-center p-[5px] rounded cursor-pointer" onClick={superficieShow}>
                                        {showSuperficie ? <i className="icofont-eye-alt"></i> : <i className="icofont-eye-blocked"></i>}
                                    </div>
                                    <input className="input-text w-[95%] ml-[10px]" type="number" min="0" step="1" placeholder="Superficie" name="superficie" value={superficie} onChange={handleChange} />
                                </div>
                                <div className="flex flex-row">
                                    <div className="w-[5%] h-[35px] bg-site-light-grey3 text-center p-[5px] rounded cursor-pointer" onClick={bagniShow}>
                                        {showBagni ? <i className="icofont-eye-alt"></i> : <i className="icofont-eye-blocked"></i>}
                                    </div>
                                    <input className="input-text w-[95%] ml-[10px]" type="number" step="1" min="0" placeholder="Bagni" name="bagni" value={bagni} onChange={handleChange} />
                                </div>
                                <div className="add-building-popup-lbl">RISCALDAMENTO</div>
                                <div className="add-building-popup-lbl">TERRAZZO</div>
                                <div className="flex flex-row">
                                    <div className="w-[5%] h-[35px] bg-site-light-grey3 text-center p-[5px] rounded cursor-pointer" onClick={riscaldamentoShow}>
                                        {showRiscaldamento ? <i className="icofont-eye-alt"></i> : <i className="icofont-eye-blocked"></i>}
                                    </div>
                                    <input className="input-text w-[95%] ml-[10px]" type="text" placeholder="Riscaldamento" name="riscaldamento" value={riscaldamento} onChange={handleChange} />
                                </div>
                                <div className="flex flex-row">
                                    <div className="w-[5%] h-[35px] bg-site-light-grey3 text-center p-[5px] rounded cursor-pointer" onClick={terrazzoShow}>
                                        {showTerrazzo ? <i className="icofont-eye-alt"></i> : <i className="icofont-eye-blocked"></i>}
                                    </div>
                                    <input className="input-text w-[95%] ml-[10px]" type="number" step="1" min="0" placeholder="Terrazzo" name="terrazzo" value={terrazzo} onChange={handleChange} />
                                </div>
                                <div className="add-building-popup-lbl">PIANO</div>
                                <div className="add-building-popup-lbl">PIANI TOTALI</div>
                                <div className="flex flex-row">
                                    <div className="w-[5%] h-[35px] bg-site-light-grey3 text-center p-[5px] rounded cursor-pointer" onClick={pianoShow}>
                                        {showPiano ? <i className="icofont-eye-alt"></i> : <i className="icofont-eye-blocked"></i>}
                                    </div>
                                    <input className="input-text w-[95%] ml-[10px]" type="text" placeholder="Piano" name="piano" value={piano} onChange={handleChange} />
                                </div>
                                <div className="flex flex-row">
                                    <div className="w-[5%] h-[35px] bg-site-light-grey3 text-center p-[5px] rounded cursor-pointer" onClick={pianiTotaliShow}>
                                        {showPianiTotali ? <i className="icofont-eye-alt"></i> : <i className="icofont-eye-blocked"></i>}
                                    </div>
                                    <input className="input-text w-[95%] ml-[10px]" type="number" step="1" min="0" placeholder="Piani Totali" name="pianiTotali" value={pianiTotali} onChange={handleChange} />
                                </div>
                                <div className="add-building-popup-lbl">PARCHEGGIO</div>
                                <div className="add-building-popup-lbl">BOX</div>
                                <div className="flex flex-row">
                                    <div className="w-[5%] h-[35px] bg-site-light-grey3 text-center p-[5px] rounded cursor-pointer" onClick={parcheggioShow}>
                                        {showParcheggio ? <i className="icofont-eye-alt"></i> : <i className="icofont-eye-blocked"></i>}
                                    </div>
                                    <input className="input-text w-[95%] ml-[10px]" type="text" placeholder="Parcheggio" name="parcheggio" value={parcheggio} onChange={handleChange} />
                                </div>
                                <div className="flex flex-row">
                                    <div className="w-[5%] h-[35px] bg-site-light-grey3 text-center p-[5px] rounded cursor-pointer" onClick={boxShow}>
                                        {showBox ? <i className="icofont-eye-alt"></i> : <i className="icofont-eye-blocked"></i>}
                                    </div>
                                    <input className="input-text w-[95%] ml-[10px]" type="checkbox" name='box' checked={box} onChange={handleChange} />
                                </div>
                                <div className="add-building-popup-lbl">ASCENSORE</div>
                                <div className="add-building-popup-lbl">CANTINA</div>
                                <div className="flex flex-row">
                                    <div className="w-[5%] h-[35px] bg-site-light-grey3 text-center p-[5px] rounded cursor-pointer" onClick={ascensoreShow}>
                                        {showAscensore ? <i className="icofont-eye-alt"></i> : <i className="icofont-eye-blocked"></i>}
                                    </div>
                                    <input className="input-text w-[95%] ml-[10px]" type="checkbox" placeholder="Ascensore" name='ascensore' checked={ascensore} onChange={handleChange} />
                                </div>
                                <div className="flex flex-row">
                                    <div className="w-[5%] h-[35px] bg-site-light-grey3 text-center p-[5px] rounded cursor-pointer" onClick={cantinaShow}>
                                        {showCantina ? <i className="icofont-eye-alt"></i> : <i className="icofont-eye-blocked"></i>}
                                    </div>
                                    <input className="input-text w-[95%] ml-[10px]" type="checkbox" placeholder="Cantina" name="cantina" checked={cantina} onChange={handleChange} />
                                </div>
                                <div className="add-building-popup-lbl">GIARDINO</div>
                                <div className="add-building-popup-lbl">STATO IMMOBILE</div>
                                <div className="flex flex-row">
                                    <div className="w-[5%] h-[35px] bg-site-light-grey3 text-center p-[5px] rounded cursor-pointer" onClick={giardinoShow}>
                                        {showGiardino ? <i className="icofont-eye-alt"></i> : <i className="icofont-eye-blocked"></i>}
                                    </div>
                                    <input className="input-text w-[95%] ml-[10px]" type="text" placeholder="Giardino" name="giardino" value={giardino} onChange={handleChange} />
                                </div>
                                <div className="flex flex-row">
                                    <div className="w-[5%] h-[35px] bg-site-light-grey3 text-center p-[5px] rounded cursor-pointer" onClick={statoImmobileShow}>
                                        {showStatoImmobile ? <i className="icofont-eye-alt"></i> : <i className="icofont-eye-blocked"></i>}
                                    </div>
                                    <input className="input-text w-[95%] ml-[10px]" type="text" placeholder="Stato Immobile" name="statoImmobile" value={statoImmobile} onChange={handleChange} />
                                </div>
                                <div className="add-building-popup-lbl">MOBILI</div>
                                <div className="add-building-popup-lbl">TERRA</div>
                                <div className="flex flex-row">
                                    <div className="w-[5%] h-[35px] bg-site-light-grey3 text-center p-[5px] rounded cursor-pointer" onClick={mobiliShow}>
                                        {showMobili ? <i className="icofont-eye-alt"></i> : <i className="icofont-eye-blocked"></i>}
                                    </div>
                                    <input className="input-text w-[95%] ml-[10px]" type="text" placeholder="Mobili" name="mobili" value={mobili} onChange={handleChange} />
                                </div>
                                <div className="flex flex-row">
                                    <div className="w-[5%] h-[35px] bg-site-light-grey3 text-center p-[5px] rounded cursor-pointer" onClick={terraShow}>
                                        {showTerra ? <i className="icofont-eye-alt"></i> : <i className="icofont-eye-blocked"></i>}
                                    </div>
                                    <input className="input-text w-[95%] ml-[10px]" type="number" step="1" min="0" placeholder="Terra" name="terra" value={terra} onChange={handleChange} />
                                </div>
                                <div className="add-building-popup-lbl">DESCRIZIONE</div>
                                <div className="add-building-popup-lbl">&nbsp;</div>
                                <div className="flex flex-row">
                                    <div className="w-[5%] h-[35px] bg-site-light-grey3 text-center p-[5px] rounded cursor-pointer" onClick={descrizioneShow}>
                                        {showDescrizione ? <i className="icofont-eye-alt"></i> : <i className="icofont-eye-blocked"></i>}
                                    </div>
                                    <textarea rows="10" cols="100" className="input-text text-area w-[95%] ml-[10px]" placeholder="Descrizione" name="descrizione" value={descrizione} onChange={handleChange} />
                                </div>
                                <div className="flex flex-row">
                                    &nbsp;
                                </div>
                                <div className="add-building-popup-lbl">SELEZIONA PER RENDERE VISIBILE</div>
                                <div className="add-building-popup-lbl">VETRINA</div>
                                <div className="flex flex-row">
                                    {/* <div className="w-[5%] h-[35px] bg-site-light-grey3 text-center p-[5px] rounded">
                                    {showVisibile ? <i className="icofont-eye-alt"></i> : <i className="icofont-eye-blocked"></i>}
                                </div> */}
                                    <input className="input-text w-[95%] ml-[10px]" type="checkbox" name='visible' checked={visible} onChange={handleChange} />
                                </div>
                                <div className="flex flex-row">
                                    {/* <div className="w-[5%] h-[35px] bg-site-light-grey3 text-center p-[5px] rounded">
                                    {showVetrina ? <i className="icofont-eye-alt"></i> : <i className="icofont-eye-blocked"></i>}
                                </div> */}
                                    <input className="input-text w-[95%] ml-[10px]" type="checkbox" name='vetrina' checked={vetrina} onChange={handleChange} />
                                </div>
                                <div className="add-building-popup-lbl">LINK</div>
                                <div className="add-building-popup-lbl"></div>
                                <div className="flex flex-row">
                                    <div className="w-[5%] h-[35px] bg-site-light-grey3 text-center p-[5px] rounded cursor-pointer" onClick={linkShow}>
                                        {showLink ? <i className="icofont-eye-alt"></i> : <i className="icofont-eye-blocked"></i>}
                                    </div>
                                    <input className="input-text w-[95%] ml-[10px]" type="text" placeholder="Link" name="link" value={link} onChange={handleChange} />
                                </div>
                                <div className="flex flex-row">
                                    &nbsp;
                                </div>
                                <div className="add-building-popup-lbl">TIPOLOGIA</div>
                                <div className="add-building-popup-lbl">&nbsp;</div>
                                <div className="flex flex-row">
                                    <div className="w-[5%] h-[35px] bg-site-light-grey3 text-center p-[5px] rounded cursor-pointer" onClick={tipologiaShow}>
                                        {showTipologia ? <i className="icofont-eye-alt"></i> : <i className="icofont-eye-blocked"></i>}
                                    </div>
                                    <select className='select2 w-[95%] ml-[10px]' name="tipologia" onChange={handleChange}>
                                        <option key="defaultTipologia" value="defaultTipologia">Tipologia</option>
                                        {flattenTypologies.map((typologies) => {
                                            return (
                                                <option key={typologies.key} value={typologies.value}>{typologies.value}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="flex flex-row">
                                    &nbsp;
                                </div>
                                <div className="flex flex-wrap">
                                    {tipologia.map((tipologia) => {
                                        return (
                                            <div className='flex mb-5' key={tipologia}>
                                                <AddImmobileFasciaLocazioneItem className="mt-5" name={tipologia} callbackRemoveLocazione={handleRemovetipologia} />
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="flex flex-row">
                                    &nbsp;
                                </div>
                                {contract.toLowerCase() === "vendita" &&
                                    <>
                                        <div className="add-building-popup-lbl">SELEZIONA FASCIA DI PREZZO</div>
                                        <div className="add-building-popup-lbl">&nbsp;</div>
                                        <div className="flex flex-row">
                                            <div className="w-[5%] h-[35px] bg-site-light-grey3 text-center p-[5px] rounded cursor-pointer" onClick={fasciaVenditaShow}>
                                                {showFasciaVendita ? <i className="icofont-eye-alt"></i> : <i className="icofont-eye-blocked"></i>}
                                            </div>
                                            <select className='select2 w-[95%] ml-[10px]' name="fasciaVendita" value={fasciaVendita} onChange={handleChange} >
                                                <option key="deafultFasciaVendita" value="deafultFasciaVendita">Fascia</option>
                                                {priceRange.map((price) => {
                                                    return (
                                                        <option key={price.key} value={price.value}>{price.value}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        <div>
                                            &nbsp;
                                        </div>
                                    </>
                                }
                                {contract.toLowerCase() === "locazione" &&
                                    <>
                                        {/* <div className="add-building-popup-lbl">PRENOTABILE DA</div>
                                        <div className="add-building-popup-lbl">PRENOTABILE FINO</div>
                                        <div className="flex flex-row">
                                            <div className="w-[5%] h-[35px] bg-site-light-grey3 text-center p-[5px] rounded cursor-pointer" onClick={prenotabileDaShow}>
                                                {showPrenotabileDa ? <i className="icofont-eye-alt"></i> : <i className="icofont-eye-blocked"></i>}
                                            </div>
                                            <DatePicker
                                                className='width-95-datepicker input-text ml-[10px]'
                                                selected={locationDateFrom}
                                                onChange={handleChangeLocationDateFrom}
                                                dateFormat="dd/MM/yyyy"
                                                isClearable
                                                showYearDropdown
                                                scrollableYearDropdown
                                            />
                                        </div>
                                        <div className="flex flex-row">
                                            <div className="w-[5%] h-[35px] bg-site-light-grey3 text-center p-[5px] rounded cursor-pointer" onClick={prenotabileFinoShow}>
                                                {showPrenotabileFino ? <i className="icofont-eye-alt"></i> : <i className="icofont-eye-blocked"></i>}
                                            </div>
                                            <DatePicker
                                                className='width-95-datepicker input-text ml-[10px]'
                                                selected={locationDateTo}
                                                onChange={handleChangeLocationDateTo}
                                                dateFormat="dd/MM/yyyy"
                                                isClearable
                                                showYearDropdown
                                                scrollableYearDropdown
                                            />
                                        </div> */}
                                        <div className="add-building-popup-lbl">SELEZIONA PERIODO DI LOCAZIONE</div>
                                        <div className="add-building-popup-lbl">&nbsp;</div>
                                        <div className="flex flex-row">
                                            <div className="w-[5%] h-[35px] bg-site-light-grey3 text-center p-[5px] rounded cursor-pointer" onClick={fasciaLocazioneShow}>
                                                {showFasciaLocazione ? <i className="icofont-eye-alt"></i> : <i className="icofont-eye-blocked"></i>}
                                            </div>
                                            <select className='select2 w-[95%] ml-[10px]' name="fasciaLocazione" onChange={handleChange}>
                                                <option key="defaultFasciaLocazione" value="defaultFasciaLocazione">Fascia</option>
                                                {leaseRange.map((lease) => {
                                                    return (
                                                        <option key={lease.key} value={lease.value}>{lease.value}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        <div>
                                            &nbsp;
                                        </div>
                                        <div className="flex flex-row">
                                            {fasciaLocazione.map((fascia) => {
                                                return (
                                                    <AddImmobileFasciaLocazioneItem key={fascia} name={fascia} callbackRemoveLocazione={handleRemoveLocazione} />
                                                )
                                            })}
                                        </div>
                                        <div>
                                            &nbsp;
                                        </div>
                                    </>
                                }
                            </div>

                            <div className='flex flex-row mt-[20px] justify-end'>
                                <button className='btn-orange text-white font-bold mt-[5px] mb-[5px] pt-[2px] pb-[2px] pl-[5px] pr-[5px] rounded-[2px] text-[15px] mr-[10px]' onClick={showAddImmobile}>ANNULLA</button>
                                <button type='submit' className='btn-orange text-white font-bold mt-[5px] mb-[5px] pt-[2px] pb-[2px] pl-[5px] pr-[5px] rounded-[2px] text-[15px]'>SALVA</button>
                            </div>
                        </form>
                        {!newItem &&
                            <div className='mt-[20px]'>
                                <table className='w-full border-site-light-grey3'>
                                    <thead>
                                        <tr className='text-claim text-[15px]'>
                                            <th className='border border-site-light-grey3 w-[120px]'>IMMAGINE</th>
                                            <th className='border border-site-light-grey3 w-[120px]'>COVER</th>
                                            <th className='border border-site-light-grey3 w-[120px]'><button className='btn-orange text-white font-bold mt-[5px] mb-[5px] pt-[2px] pb-[2px] pl-[5px] pr-[5px] rounded-[2px] text-[15px] mr-[10px]' onClick={saveImagesOrder}>SALVA ORDINAMENTO</button> </th>
                                            <th className='border border-site-light-grey3 w-[120px]'><button className='btn-orange text-white font-bold mt-[5px] mb-[5px] pt-[2px] pb-[2px] pl-[5px] pr-[5px] rounded-[2px] text-[15px] mr-[10px]' onClick={clickShowImages}>VISUALIZZA IMMAGINI</button> </th>
                                            <th className='border border-site-light-grey3 w-[120px]'>
                                                {/* <button htmlFor="imageInput" className='btn-orange text-white font-bold mt-[5px] mb-[5px] pt-[2px] pb-[2px] pl-[5px] pr-[5px] rounded-[2px] text-[15px] mr-[10px]'>AGGIUNGI</button> */}
                                                <label htmlFor="imageInput" className='btn-orange cursor-pointer text-white font-bold mt-[5px] mb-[5px] pt-[2px] pb-[2px] pl-[5px] pr-[5px] rounded-[2px] text-[15px] mr-[10px] inline-block'>
                                                    SELEZIONA IMMAGINE
                                                </label>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={handleImageChange}
                                                    style={{ display: 'none' }}
                                                    id="imageInput"
                                                    multiple
                                                />
                                            </th>
                                        </tr>
                                    </thead>
                                    {
                                        showImages &&
                                        <tbody>
                                            {selectedImages.map((image, index) => {
                                                return (
                                                    <AddImmobileImage key={image.url} image={image} index={image.url} callBackOrder={callBackOrder} callBackDeleteImage={callBackDeleteImage} callBackHandleCover={callbackHandleCover} />
                                                )
                                            })}
                                        </tbody>
                                    }
                                </table>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddImmobile;