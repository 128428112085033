import { createContext, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { db, storage } from '../firebase'
import {
  addDoc,
  collection,
  deleteDoc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  doc,
  where,
  limit,
  serverTimestamp,
  startAfter,
} from 'firebase/firestore'
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid'
import { dexie } from './localdb'
import { CONTRACTS, PRICE_RANGE, LEASE_RANGE, flattenArray, createAdvertisementID } from './CommonFunction'

const FirestoreContext = createContext()

const FirestoreProvider = ({ children }) => {
  const BUILDING_TIMESTAMP_PROPERTY_NAME = "timestamp";

  const typologiesCollectionName = 'typologies'
  const buildingsCollectionName = 'buildings'
  const boxCollectionName = 'box'
  const settingsCollectionName = "settings"
  const typologiesCollection = collection(db, typologiesCollectionName)
  const buildingsCollection = collection(db, buildingsCollectionName)
  const boxCollection = collection(db, boxCollectionName)
  const settingsCollection = collection(db, settingsCollectionName)

  const locationsCollectionName = 'locations';
  const locationsCollection = collection(db, locationsCollectionName)
  const [typologies, setTypologies] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [priceRange, setPriceRange] = useState([]);
  const [leaseRange, setLeaseRange] = useState([]);
  const [flattenTypologies, setFlattenTypologies] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [boxList, setBoxList] = useState([]);

  const retrieveContracts = () => {
    setContracts(CONTRACTS);
    return CONTRACTS;
  }

  // #region TYPOLOGIES
  const addParentTypology = async (typology) => {
    if (!!typology) {
      await addDoc(collection(db, typologiesCollectionName), typology)
        .then(async (res) => {
          typology["id"] = res.id;
          await dexie.typologies.bulkAdd([typology]);
        })
        .catch((err) => {
          throw err
        })
    }
  }

  const addSubTypology = async (parentTypology, subTypology, subTypologyOrder) => {
    const docRef = doc(db, typologiesCollectionName, parentTypology.id);

    if (parentTypology.children === null || parentTypology.children === undefined) {
      parentTypology.children = [];
    }

    var child = {
      value: subTypology,
      key: uuidv4(),
      order: Number(subTypologyOrder)
    }

    parentTypology.children.push(child);

    await updateDoc(docRef, { children: parentTypology.children })
      .then(async () => {
        await dexie.typologies.update(parentTypology.id, { children: parentTypology.children });
        return true;
      }).catch((err) => {
        console.log(err);
        return false;
      });
  }

  const deleteParentTypology = async (typologyID) => {
    await deleteDoc(doc(db, typologiesCollectionName, typologyID))
      .then(async () => {
        await dexie.typologies.where("id").anyOf(typologyID).delete()
          .then(() => {
            return true;
          });
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  }

  const updateParentTypology = async (typologyID, typologyValue, typologyOrder) => {
    const docRef = doc(db, typologiesCollectionName, typologyID);
    await updateDoc(docRef, { value: typologyValue, order: typologyOrder })
      .then(async () => {
        await dexie.typologies.update(typologyID, { value: typologyValue, order: typologyOrder });
        return true;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });

  }

  const updateChildTypology = async (parentTypology) => {
    const docRef = doc(db, typologiesCollectionName, parentTypology.id);

    await updateDoc(docRef, { children: parentTypology.children })
      .then(async () => {
        await dexie.typologies.update(parentTypology.id, { children: parentTypology.children });
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  }

  const deleteSubTypology = async (parent, childID) => {
    const docRef = doc(db, typologiesCollectionName, parent.id);

    const found = parent.children.find(x => x.id = childID);
    parent.children.pop(found[0]);

    await updateDoc(docRef, { children: parent.children })
      .then(async () => {
        await dexie.typologies.update(parent.id, { children: parent.children });
      })
      .catch((err) => {
        console.log(err);
        return false;
      })
  }

  const retrieveTypologies = useCallback(async () => {
    await internalRetrieveTypologies()
      .then((res) => {
        setTypologies(res);
      });
  }, [typologiesCollection])

  const retrieveFlattenTypologies = async () => {
    await internalRetrieveTypologies()
      .then((res) => {
        setFlattenTypologies(flattenArray(res));
      });
  }

  const internalRetrieveTypologies = async () => {
    var p = new Promise(async (resolve, reject) => {
      var q = query(typologiesCollection, orderBy('value', 'asc'))
      await getDocs(q)
        .then(async (result) => {
          const newData = result.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id
          }))
          resolve(newData);
        })
        .catch((err) => {
          console.log(err)
          reject(err);
        })
    });
    return p;
  }
  // #endregion

  // #region LOCATIONS

  const [locations, setLocations] = useState([]);
  const getLocations = useCallback(async () => {
    const localLocations = await dexie.locations.toArray()
    if (localLocations.length > 0) {
      const sortedLocations = localLocations.sort((a, b) => a.value - b.value);
      setLocations(sortedLocations);
      return;
    }

    const q = query(locationsCollection, orderBy('value', "asc"));
    await getDocs(q)
      .then((async (res) => {
        const newData = res.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id
        }));

        await dexie.locations.bulkAdd(newData.sort((a, b) => a.value - b.value));
        setLocations(await dexie.locations.toArray());
      }))
      .catch((err) => {
        console.log(err);
      })

  })

  const getRemoteLocations = async () => {
    var p = new Promise(async (resolve, reject) => {
      const q = query(locationsCollection, orderBy('value', "asc"));
      await getDocs(q)
        .then(async (res) => {
          const newData = res.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id
          }))
          let app = newData.sort((a, b) => a.value - b.value)
          resolve(app);
        })
        .catch((err) => {
          reject(err)
        })
    })

    return p;
  }


  const addLocation = async (location) => {
    if (!!location) {
      await addDoc(collection(db, locationsCollectionName), location)
        .then(async (res) => {
          location["id"] = res.id;
          await dexie.locations.bulkAdd([location]);
          return false;
        })
        .catch((err) => {
          console.log(err);
          return err;
        })
    }
  }

  const deleteLocation = async (locationID) => {
    await deleteDoc(doc(db, locationsCollectionName, locationID))
      .then(async () => {
        await dexie.locations.where("id").anyOf(locationID).delete()
          .then(() => {
            return true;
          });
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  }

  const updateLocation = async (locationID, locationValue) => {
    const docRef = doc(db, locationsCollectionName, locationID);
    await updateDoc(docRef, { value: locationValue })
      .then(async () => {
        await dexie.locations.update(locationID, { value: locationValue });
        return true;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });

  }

  // #endregion

  // #region BUILDINGS

  const insertBuilding = async (building) => {
    if (!!building) {
      building.fasciaLocazione = arrayToMapFasciaLocazione(building.fasciaLocazione);
      let p = new Promise(async (resolve, reject) => {
        await getSettings()
          .then(async (success) => {
            building["idAnnuncio"] = createAdvertisementID(success.annuncio.root, success.annuncio.total);
            building[BUILDING_TIMESTAMP_PROPERTY_NAME] = serverTimestamp();
            await addDoc(collection(db, buildingsCollectionName), building)
              .then(async (res) => {
                await updateSettingAnnuncioTotal(success.id, success.annuncio.total + 1)
                  .then(() => {
                    resolve(res.id);
                  })
                  .catch((err) => {
                    reject(err);
                  })
              })
              .catch((err) => {
                reject(err)
              })
          })
          .catch((error) => {
            reject(error);
          })
      });

      return p;
    }
  }

  const updateBuilding = async (building) => {
    if (!!building) {
      building.fasciaLocazione = arrayToMapFasciaLocazione(building.fasciaLocazione);
      let p = new Promise(async (resolve, reject) => {
        const docRef = doc(db, buildingsCollectionName, building.id);
        // delete building.id;
        building[BUILDING_TIMESTAMP_PROPERTY_NAME] = serverTimestamp();
        await updateDoc(docRef, building)
          .then(async () => {
            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
      });
      return p;
    }
  }

  const updateBuildingImages = async (buildingID, images) => {
    let p = new Promise(async (resolve, reject) => {
      const docRef = doc(db, buildingsCollectionName, buildingID);
      await updateDoc(docRef, { images: images })
        .then(async () => {
          resolve(true);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return p;
  }

  const getBuildings = async (localita, contratto, tipologia, custombox) => {
    var q = query(buildingsCollection)
    if (localita ?? false) {
      q = query(q, where("city.value", "==", localita));
    }

    if (contratto ?? false) {
      q = query(q, where("contract.value", "==", contratto));
    }

    if (tipologia ?? false) {
      q = query(q, where("tipologia.value", "array-contains-any", [tipologia.replace("-", "")]));
    }

    if (custombox ?? false) {
      q = query(q, where("custombox", "==", custombox))
    }

    var p = new Promise(async (resolve, reject) => {
      await getDocs(q)
        .then(async (result) => {
          const newData = result.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id
          }))
          newData.forEach(data => {
            let newFasciaLocazione = mapToArrayfixFasciaLocazione(data);
            data.fasciaLocazione = newFasciaLocazione;
          })
          setBuildings(newData);
          resolve(newData);
        })
        .catch((err) => {
          reject(err)
        })
    })

    return p;
  }

  const mapToArrayfixFasciaLocazione = (data) => {
    let fasciaLocazioneKey = Object.keys(data['fasciaLocazione']);
    if (fasciaLocazioneKey.length === 1) {
      return {
        visible: data.fasciaLocazione.visible,
        value: []
      }
    } else {
      return {
        visible: data.fasciaLocazione.visible,
        value: fasciaLocazioneKey.filter(val => val !== "visible")
      }
    }
  }

  const arrayToMapFasciaLocazione = (data) => {
    let fasciaLocazione = {
      visible: data.visible
    }

    if (data.value.length > 0) {
      data.value.forEach(val => {
        fasciaLocazione[val] = true;
      })
    }

    return fasciaLocazione;
  }

  const getBuildingsSearch = async (localita, contratto, tipologia, price, lease, start) => {
    var q = query(buildingsCollection)
    if (localita ?? false) {
      q = query(q, where("city.value", "==", localita));
    }

    if (contratto ?? false) {
      q = query(q, where("contract.value", "==", contratto));
    }

    if (tipologia ?? false) {
      q = query(q, where("tipologia.value", "array-contains-any", [tipologia.replace("-", "")]));
    }

    if (price ?? false) {
      q = query(q, where("fasciaVendita.value", "==", price))
    }

    if (lease ?? false) {
      q = query(q, where("fasciaLocazione." + lease, "==", true))
    }
    q = query(q, where("visible.value", '==', true));
    q = query(q, orderBy("timestamp", "desc"), limit(10));

    if (start ?? false) {
      q = query(q, startAfter(start.timestamp))
    }

    var p = new Promise(async (resolve, reject) => {
      await getDocs(q)
        .then(async (result) => {
          const newData = result.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id
          }))
          resolve(newData);
        })
        .catch((err) => {
          reject(err)
        })
    })

    return p;
  }

  const updateBuildingCustomBox = async (buildingID, custombox) => {
    var p = new Promise(async (resolve, reject) => {
      const docRef = doc(db, buildingsCollectionName, buildingID);
      await updateDoc(docRef, { custombox: custombox })
        .then(() => {
          resolve(true);
        })
        .catch((err) => {
          reject(err);
        })
    });

    return p;
  }

  const deleteBuilding = async (buildingID) => {
    var p = new Promise(async (resolve, reject) => {
      const docRef = doc(db, buildingsCollectionName, buildingID);
      await deleteDoc(docRef)
        .then(() => {
          resolve(true)
        })
        .catch((err) => {
          reject(err);
        })
    });

    return p;
  }

  const getBuildingByID = async (id) => {
    var q = query(buildingsCollection, where("idAnnuncio", "==", id));
    var p = new Promise(async (resolve, reject) => {
      await getDocs(q)
        .then(async (result) => {
          const newData = result.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id
          }))
          newData.forEach(data => {
            let newFasciaLocazione = mapToArrayfixFasciaLocazione(data);
            data.fasciaLocazione = newFasciaLocazione;
          })
          setBuildings(newData);
          resolve(newData);
        })
        .catch((err) => {
          reject(err)
        })
    })

    return p;
  }

  // #endregion

  // #region IMAGES

  const uploadImage = async (file) => {
    if (!!file) {
      let p = new Promise(async (resolve, reject) => {
        const storageRef = ref(storage, file.name);
        uploadBytesResumable(storageRef, file)
          .then((snap) => {
            getDownloadURL(snap.ref)
              .then((url) => {
                resolve(url);
              })
              .catch((err) => {
                reject(err);
              })
          })
          .catch((err) => {
            reject(err);
          })
      });
      return p;
    }
  }

  const deleteImage = async (fileName) => {
    let p = new Promise(async (resolve, reject) => {
      const storageRef = ref(storage, fileName);
      deleteObject(storageRef)
        .then((success) => {
          resolve(true);
        })
        .catch((err) => {
          reject(err);
        })
    });
    return p;
  }

  // #endregion


  // #region BOX

  const getBox = async () => {
    var q = query(boxCollection);
    var p = new Promise(async (resolve, reject) => {
      await getDocs(q)
        .then((result) => {
          const newData = result.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id
          }));
          setBoxList(newData);
          resolve(newData);
        })
        .catch((err) => {
          reject(err);
        })
    })

    return p;
  }

  const updateBox = async (boxID, boxName) => {
    var p = new Promise(async (resolve, reject) => {
      const docRef = doc(db, boxCollectionName, boxID);
      await updateDoc(docRef, { name: boxName })
        .then(async () => {
          resolve(true);
        })
        .catch((err) => {
          reject(err);
        });
    })

    return p;
  }

  // #endregion

  // #region SETTINGS

  const getSettings = async () => {
    var q = query(settingsCollection);
    const p = new Promise(async (resolve, reject) => {
      await getDocs(q)
        .then((success) => {
          const newData = success.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id
          }));

          resolve(newData[0]);
        })
        .catch((error) => {
          reject(error);
        })
    })

    return p;
  }

  const updateSettingAnnuncioTotal = async (id, newTotal) => {
    var p = new Promise(async (resolve, reject) => {
      const docRef = doc(db, settingsCollectionName, id);
      await updateDoc(docRef, { "annuncio.total": newTotal })
        .then(async () => {
          resolve(true);
        })
        .catch((err) => {
          reject(err);
        });
    });

    return p;
  }

  // #endregion

  const getPriceRange = () => {
    setPriceRange(PRICE_RANGE);
    return PRICE_RANGE;
  }

  const getLeaseRange = () => {
    setLeaseRange(LEASE_RANGE);
    return LEASE_RANGE;
  }

  const value = {
    locations,
    typologies,
    contracts,
    priceRange,
    leaseRange,
    flattenTypologies,
    buildings,
    boxList,
    getBuildingByID,
    getLocations,
    getRemoteLocations,
    getPriceRange,
    getLeaseRange,
    addLocation,
    deleteLocation,
    updateLocation,
    addParentTypology,
    retrieveTypologies,
    deleteParentTypology,
    updateParentTypology,
    addSubTypology,
    deleteSubTypology,
    updateChildTypology,
    retrieveFlattenTypologies,
    retrieveContracts,
    insertBuilding,
    getBuildings,
    updateBuilding,
    updateBuildingImages,
    uploadImage,
    updateBuildingCustomBox,
    deleteImage,
    getBox,
    updateBox,
    deleteBuilding,
    getSettings,
    getBuildingsSearch
  }

  return (
    <FirestoreContext.Provider value={value}>
      {children}
    </FirestoreContext.Provider>
  )
}

FirestoreProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export { FirestoreContext, FirestoreProvider }
