import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { BASE_LINK, formatCurrency, isNullOrWhitespace } from './CommonFunction';
import { useContext, useEffect, useRef, useState } from 'react';
import Toast from './Toast';
import copy from 'copy-to-clipboard';
import { FirestoreContext } from './FirestoreProvider';
import Slider from './slider';
import emailjs from '@emailjs/browser';

const Annuncio = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const excludedProps = [
        "descrizione",
        "id",
        "idAnnuncio",
        "images",
        "key",
        "link",
        "vetrina",
        "visible",
        "tipologia",
        "fasciaVendita",
        "fasciaLocazione",
        "contract",
        "city",
        "indirizzo",
        "prezzo"
    ]

    const propertyName = [
        { original: 'city', name: "città" },
        { original: 'classeEnergetica', name: "classe energetica" },
        { original: 'contract', name: "contratto" },
        { original: 'pianiTotali', name: "piani totali" },
        { original: 'statoImmobile', name: "stato immobile" },
        { original: 'fasciaVendita', name: "fascia di vendita" },
        { original: 'fasciaLocazione', name: "periodo locazione" },
    ]

    let { id } = useParams();

    const [isError, setIsError] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);
    const { getBuildingByID } = useContext(FirestoreContext);
    const [annuncio, setAnnuncio] = useState(null);
    const [annuncioProps, setAnnuncioProps] = useState([]);
    const [images, setImages] = useState([]);
    const [annuncioLink, setAnnuncioLink] = useState(BASE_LINK);
    const [showSlider, setShowSlider] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(-1);
    const [showPrezzo, setShowPrezzo] = useState(false);
    const [locazione, setLocazione] = useState([]);

    const [localita, setLocalita] = useState("");
    const [contract, setContract] = useState("");
    const [tipology, setTipolgy] = useState("");
    const [price, setPrice] = useState("");
    const [lease, setLease] = useState("");
    const [itemList, setItemList] = useState([]);
    const [runSearch, setRunSearch] = useState(false);
    const [haveMore, setHaveMore] = useState(false);
    const [index, setIndex] = useState(-1);

    const mailForm = useRef();

    const setMessage = (isError, message) => {
        setToastMessage(message);
        setIsError(isError);
        setShowToast(true);
    }

    const onCloseToast = () => {
        setShowToast(false);
    }

    const copyToClipboard = async () => {
        try {
            copy(annuncioLink);
            setMessage(false, "Link annuncio copiato con successo");
        }
        catch (error) {
            setMessage(true, "Errore durante la copia del link.: " + error.message);
        }
    }

    const showImageSlider = (index) => {
        setCurrentImageIndex(index);
        if (isNaN(index)) {
            setCurrentImageIndex(-1);
        }
        let app = !showSlider;
        setShowSlider(app)
    }


    useEffect(() => {
        if (isNullOrWhitespace(id)) {
            navigate('/');
        }
        setAnnuncioLink(BASE_LINK + id);
        getBuildingByID(id)
            .then((result) => {
                var annuncioApp = result[0];
                setAnnuncio(result[0]);
                if (annuncioApp.images ?? false) {
                    let appImages = annuncioApp.images.sort((a, b) => a.order - b.order);
                    setImages(appImages);
                }
                let objectKey = Object.keys(annuncioApp);
                let props = [];
                objectKey.forEach((prop) => {
                    if (!excludedProps.includes(prop)) {
                        if (annuncioApp[prop] !== null && annuncioApp[prop] !== undefined && annuncioApp[prop].visible == true && !isNullOrWhitespace(annuncioApp[prop].value)) {
                            let val = annuncioApp[prop].value;
                            if (typeof val === 'boolean') {
                                if (val) {
                                    val = 'SI';
                                } else {
                                    val = 'NO';
                                }
                            }
                            let mappedProp = {
                                value: val,
                                name: prop
                            };
                            let mapped = propertyName.find(p => p.original === prop);
                            if (mapped) {
                                mappedProp.name = mapped.name;
                            }

                            props.push(mappedProp);
                        }
                    }
                    if (prop === 'fasciaLocazione') {
                        setLocazione(annuncioApp[prop].value);
                    }
                })
                if (props.length > 0) {
                    let orderd = props.sort(compare);
                    setAnnuncioProps(orderd);
                }
                if (annuncioApp.contract.value === 'Vendita' && annuncioApp.prezzo.visible === true) {
                    setShowPrezzo(true);
                }
            })
            .catch((error) => {
                console.log(error);
                setMessage(true, "Errore durante il caricamento dell'annuncio");
            })
        if (location.state ?? false) {
            const { localita, contract, tipology, price, lease, itemList, haveMore, index } = location.state
            setLocalita(localita);
            setContract(contract);
            setTipolgy(tipology);
            setPrice(price);
            setLease(lease);
            setItemList(itemList);
            setRunSearch(false);
            setHaveMore(haveMore);
            setIndex(index);
        } else {
            setRunSearch(true);
        }

    }, [])

    function compare(a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    }

    const backToSearch = () => {
        navigate("/cerca", {
            state: {
                localita: localita, contract: contract, tipology: tipology, price: price, lease: lease, runSearch: runSearch, itemList: itemList, hasMore:haveMore, index:index
            }
        })
    }
    const mailTemplate = process.env.REACT_APP_EMAIL_TEMPLATE_ID;
    const serviceID = process.env.REACT_APP_EMAIL_SERVICE_ID;
    const publicKey = process.env.REACT_APP_EMAIL_PUBLICK_KEY;
    const sendMail = (event) => {
        showLoader(true);
        event.preventDefault();
        console.log(process.env);
        emailjs.sendForm(serviceID, mailTemplate, mailForm.current, { publicKey: publicKey })
            .then(() => {
                showLoader(false);
                setMessage(false, "Email inviata con successo, verrai contattato a breve.");
            })
            .catch((error) => {
                showLoader(false);
                console.log(error);
                setMessage(true, "Errore invio mail, riprovare più tardi");
            })
    }

    const [loading, setLoading] = useState(false);
    const showLoader = (show) => {
        setLoading(show);
    }

    return (
        <>
            {
                loading && <div className="overlay"><div className="spinner"></div></div>
            }
            {showToast &&
                <Toast isError={isError} message={toastMessage} duration={3000} whenClose={onCloseToast} />
            }
            {
                !annuncio &&
                <div className="overlay"><div className="spinner"></div></div>

            }
            {
                annuncio &&
                <>
                    {
                        showSlider &&
                        <Slider showSliderCallBask={showImageSlider} index={currentImageIndex} images={images} />
                    }
                    <div className='flex xl:flex-row flex-col justify-center xl:items-start items-center w-full mb-[30px] xl:mt-[30px]'>
                        <div className='flex flex-col xl:mt-0 md:mt-[20px] mt-[20px] xl:w-[800px] md:w-[750px] w-[350px]'>
                            <button onClick={backToSearch} className='btn-orange text-white font-bold mt-[5px] mb-[5px] pt-[2px] pb-[2px] pl-[5px] pr-[5px] rounded-[2px] text-[15px]'>TORNA ALLA RICERCA</button>
                            <div className='font-bold text-xl text-claim border-t-[3px] border-t-site-lite-orange border-b-[3px] border-b-site-lite-orange mt-[10px] pl-[10px]'>
                                <span className='capitalize'>{annuncio.tipologia.value.at(-1)}</span> in <span className='capitalize'>{annuncio.contract.value}</span>
                                {showPrezzo
                                    &&
                                    <><span> - </span> <span>{annuncio.prezzo.value}</span></>
                                }
                            </div>
                            <div className='mt-[10px] grid xl:grid-cols-4 md:grid-cols-4 grid-cols-2 gap-4'>
                                {images.map((image, index) => {
                                    return (
                                        <div className='cursor-pointer' key={index}><img src={image.url} alt='' onClick={() => { showImageSlider(index) }} /></div>
                                    )
                                })}
                            </div>
                            <div className='mb-[10px] font-bold text-xl text-claim border-t-[3px] border-t-site-lite-orange border-b-[3px] border-b-site-lite-orange mt-[10px] pl-[10px]'>
                                Dettagli
                            </div>
                            <div className='grid grid-cols-1 mb-[10px]'>
                                {
                                    (annuncio.contract.visible && annuncio.contract.value !== null) &&
                                    <div className='mt-[10px] mr-[10px] text-claim p-[5px] bg-site-light-grey3 w-fit h-fit'>
                                        <span className='capitalize'>Contratto</span>: <b>{annuncio.contract.value}</b>
                                    </div>
                                }
                                {
                                    (annuncio.city.visible && annuncio.city.value !== null) &&
                                    <div className='mt-[10px] mr-[10px] text-claim p-[5px] bg-site-light-grey3 w-fit h-fit'>
                                        <span className='capitalize'>Città</span>: <b>{annuncio.city.value}</b>
                                    </div>
                                }
                                {
                                    (annuncio.indirizzo.visible && annuncio.indirizzo.value !== null && annuncio.indirizzo.value !== '') &&
                                    <div className='mt-[10px] mr-[10px] text-claim p-[5px] bg-site-light-grey3 w-fit h-fit'>
                                        <span className='capitalize'>Indirizzo</span>: <b>{annuncio.indirizzo.value}</b>
                                    </div>
                                }
                                {
                                    (annuncio.prezzo.visible && annuncio.prezzo.value !== null && annuncio.prezzo.value !== '') &&
                                    <div className='mt-[10px] mr-[10px] text-claim p-[5px] bg-site-light-grey3 w-fit h-fit'>
                                        <span className='capitalize'>Prezzo</span>: <b>{formatCurrency(annuncio.prezzo.value)}</b>
                                    </div>
                                }

                                {annuncioProps.map((prop, index) => {
                                    return (
                                        <div key={index} className='mt-[10px] mr-[10px] text-claim p-[5px] bg-site-light-grey3 w-fit h-fit'>
                                            <span className='capitalize'>{prop.name}</span>: <b>{prop.value}</b>
                                        </div>
                                    )
                                })}
                            </div>
                            {
                                (locazione !== null && locazione.length > 0) &&
                                <div className='mb-[10px] font-bold text-xl text-claim border-t-[3px] border-t-site-lite-orange border-b-[3px] border-b-site-lite-orange mt-[10px] pl-[10px]'>
                                    Periodo Locazione
                                </div>
                            }
                            {
                                (locazione !== null && locazione.length > 0) &&
                                <>
                                    {
                                        locazione.map((loc, index) => {
                                            return (
                                                <div key={index} className='mt-[10px] mr-[10px] text-claim p-[5px] bg-site-light-grey3 w-fit h-fit'>
                                                    <b><span className='capitalize'>{loc}</span></b>
                                                </div>
                                            )
                                        })
                                    }
                                </>
                            }

                            <div className='mb-[10px] font-bold text-xl text-claim border-t-[3px] border-t-site-lite-orange border-b-[3px] border-b-site-lite-orange mt-[10px] pl-[10px]'>
                                Descrizione
                            </div>
                            <div className='mb-[10px] mr-[10px] text-claim p-[5px] text-justify' dangerouslySetInnerHTML={{ __html: annuncio.descrizione.value.replace('\n', '<br />') }}>
                            </div>
                            {
                                (annuncio.link.value !== null && annuncio.link.value !== undefined && annuncio.link.visible) &&
                                <div className='mb-[10px] mr-[10px] text-claim p-[5px] text-justify'>
                                    Video della casa <a className='no-style-link' href={annuncio.link.value} target='_blank'>cliccare qui</a>
                                </div>

                            }
                            <div className='mb-[10px] lnk-gen cursor-pointer break-all' onClick={copyToClipboard}>{annuncioLink}</div>
                            <button onClick={backToSearch} className='btn-orange text-white font-bold mt-[10px] mb-[5px] pt-[2px] pb-[2px] pl-[5px] pr-[5px] rounded-[2px] text-[15px]'>TORNA ALLA RICERCA</button>
                        </div>
                        <div className='p-[20px] xl:w-[350px] md:w-[750px] w-[350px] bg-site-light-grey2 xl:ml-[10px] ml-0 xl:mt-0 mt-[10px]'>
                            <form ref={mailForm} onSubmit={sendMail}>
                                <div className='font-bold text-xl text-claim border-t-[3px] border-t-site-lite-orange border-b-[3px] border-b-site-lite-orange pl-[10px]'>
                                    Richiedi Informazioni
                                </div>
                                <input type='text' name='idAnnuncio' className='hidden' value={id} readOnly />
                                <input type='text' name='linkAnnuncio' className='hidden' value={annuncioLink} readOnly />
                                <div className='mt-[10px]'>
                                    <input required type='text' placeholder='Nome' name='name' className='input-text w-full' />
                                </div>
                                <div className='mt-[10px]'>
                                    <input required type='text' placeholder='Cognome' name='surname' className='input-text w-full' />
                                </div>
                                <div className='mt-[10px]'>
                                    <input type='text' placeholder='Telefono' name='phone' className='input-text w-full' />
                                </div>
                                <div className='mt-[10px]'>
                                    <input required type='email' placeholder='Email' name='email' className='input-text w-full' />
                                </div>
                                <div className='mt-[10px]'>
                                    <textarea required rows="10" cols="100" name='info' className="input-text text-area w-full" placeholder="Vorrei sapere..." />
                                </div>
                                <div className='mt-[10px] flex flex-row'>
                                    <button type='button' className='grow btn-orange text-white font-bold mt-[5px] mr-[10px] mb-[5px] pt-[2px] pb-[2px] pl-[5px] pr-[5px] rounded-[2px] text-[15px]'>ANNULLA</button>
                                    <input type='submit' className='grow btn-orange text-white font-bold mt-[5px] mb-[5px] pt-[2px] pb-[2px] pl-[5px] pr-[5px] rounded-[2px] text-[15px]' value="INVIA" />
                                </div>
                            </form>
                        </div>
                    </div>
                </>
            }
        </>
    )

}

export default Annuncio;