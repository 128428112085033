import { useEffect, useState } from "react";

const AddImmobileImage = ({ index, image, callBackOrder, callBackDeleteImage, callBackHandleCover }) => {

    const [currentImage, setCurrentImage] = useState({});
    const [curretOrder, setCurrentOrder] = useState(0);

    const handleImageOrder = (e) => {
        const order = Number(e.target.value);
        setCurrentOrder(order);
        currentImage.position = order;
        setCurrentImage(currentImage);
        callBackOrder(image);
    }

    const handleDelete = () => {
        callBackDeleteImage(currentImage);
    }

    const handleCover = () => {
        currentImage.cover = true;
        setCurrentImage(currentImage);
        callBackHandleCover(currentImage);
    }

    useEffect(() => {
        setCurrentImage(image);
        setCurrentOrder(image.position);
    }, [])

    return (
        <tr key={index}>
            <td className='border border-site-light-grey3 p-[10px]'>
                <div className="flex flex-col">
                    <img src={image.url} alt="" width="100" height="75" />
                    {
                        image.error !== "" && 
                        <div className="text-red-600">{image.error}</div>
                    }
                </div>
            </td>
            <td className='border border-site-light-grey3 p-[10px]'>
                {image.cover &&
                    <div className='flex flex-row justify-center'>
                        <i className="icofont-tick-mark text-site-orange"></i>
                    </div>
                }
            </td>
            <td className='border border-site-light-grey3 p-[10px]' colSpan="2">
                <input className="input-text w-full mr-[5px]" name={image.id} type="number" min={0} value={curretOrder} placeholder="Ordine" onChange={handleImageOrder} />
            </td>
            <td className='border border-site-light-grey3 p-[10px]'>
                <div className='flex flex-row w-full justify-center'>
                    <button className='btn-orange text-white font-bold mt-[5px] mb-[5px] pt-[2px] pb-[2px] pl-[5px] pr-[5px] rounded-[2px] text-[15px] mr-[10px]' onClick={handleCover} >COVER</button>
                    <button className='btn-orange text-white font-bold mt-[5px] mb-[5px] pt-[2px] pb-[2px] pl-[5px] pr-[5px] rounded-[2px] text-[15px] mr-[10px]' onClick={handleDelete}>ELIMINA</button>
                </div>
            </td>
        </tr>
    )

}

export default AddImmobileImage;